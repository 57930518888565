<template lang="pug">
    .panel.panel-transparent
        .panel-heading
            i.fa.fa-clock-o.fa-fw.text-muted.mr3
            span.text-muted {{'dashboard.simple.EVENTS'|translate}}

        div(style="position:relative")
            OptiNotificationsItem(v-for="notification in notifications" :key="notification.id"
                :notification="notification")

            .text-muted.text-center(v-show="!notifications.length && !loading")
                i.fa.fa-exclamation-circle.mr3
                span {{'default.table.EMPTY'|translate}}

            OptiDimmer(:active="loading")

        .clearfix.text-center(style="background-color:transparent;padding:5px 10px" v-show="notifications.length")
            OptiButton(:disabled="loading" @click.native="toggle()"
                ) {{'dashboard.notifications.toggle.' + (show_all ? '10' : 'ALL')|translate}}
                template(#icon)
                    i.fa(:class="{'fa-angle-double-up' : show_all, 'fa-angle-double-down': !show_all}")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import Broadcast from '@/js/app/vue/Broadcast'

    import OptiNotificationsItem from '@/js/app/vue/components/Dashboard/Simple/Notification/OptiNotificationsItem'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiNotifications',
        components: {
            OptiNotificationsItem,
            OptiButton,
            OptiDimmer
        },
        data() {
            return {
                notifications: [],

                channel_name: 'department-notifications-' + this.$uac.user.activeDepartmentId,
                show_all: false,
                loading: true
            }
        },
        mounted() {
            this.load()

            Broadcast.Echo.channel(this.channel_name).listen('.updated', () => {
                this.load()
            })
        },
        destroyed() {
            Broadcast.Echo.channel(this.channel_name).unsubscribe()
        },
        methods: {
            load() {
                this.loading = true

                API.get('dashboard/notifications/' + (this.show_all ? 1 : 0), API.id(Math.random())).then(res => {
                    this.notifications = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('dashboard.notifications.notify.error.LOAD')
                })
            },
            toggle() {
                this.show_all = !this.show_all

                this.load()
            }
        }
    }
</script>