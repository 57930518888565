<template lang="pug">
    .panel.panel-transparent
        .panel-heading
            i.fa.fa-ban.text-muted.mr3
            span.text-muted {{'role.exceptions.dashboard.HEADER'|translate}}
            label.label.label-danger.ml3(v-show="locks.length") {{locks.length}}

        .action-div(style="position:relative")
            div(v-for="(lock, index) in locks.slice(0, show_all ? locks.length : 2)" :class="{'bg-separator': index % 2}")
                div
                    div {{lock.permission.name}}
                    small.text-muted-on-dark {{lock.permission.translate}}
                div
                    div {{lock.user.last_name}} {{lock.user.first_name}}
                    small.text-muted-on-dark {{lock.user.department_name}}
                div
                    div {{'role.exceptions.dashboard.PERIOD'|translate}}
                    small.text-muted-on-dark {{lock.period}}

            .text-center.text-muted(v-show="!locks.length")
                i.fa.fa-exclamation-circle.mr3
                span {{'default.table.EMPTY'|translate}}

            .text-center(style="margin-top:10px" v-show="locks.length > 2")
                .btn.btn-default(@click="show_all = !show_all")
                    span(v-show="!show_all") {{'role.exceptions.dashboard.btn.rows.SHOW_ALL'|translate}}
                    span(v-show="show_all") {{'role.exceptions.dashboard.btn.rows.SHOW_NEWEST'|translate}}

            div(v-if="permission_admin")
                .text-center(style="margin:10px")
                    OptiButton(@click.native="$state.go('app.roles.exceptions')") {{'sidebar.users.EXCEPTIONS'|translate}}
                        template(#icon)
                            i.fa.fa-user

                .text-center
                    OptiButtonHistory(endpoint="roles/exceptions/logs")

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRoleExceptionDashboard',
        components: {
            OptiButton,
            OptiButtonHistory,
            OptiDimmer
        },
        data() {
            return {
                locks: [],

                permission_admin: this.$uac.permission('admin_plus.edit'),
                show_all: false,
                loading: true
            }
        },
        mounted() {
            API.get('roles/exceptions/dashboard').then(res => {
                this.locks = res.data
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('role.exceptions.dashboard.notify.error.DASHBOARD_LOAD')
            })
        }
    }
</script>