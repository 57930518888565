<template lang="pug">
    .row
        .col-lg-6.col-lg-offset-3.col-md-8.col-md-offset-2
            .panel.panel-primary(style="position:relative")
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'transfers.create.CREATE'|translate}}

                form(style="padding:10px 15px" name="formTransfer")
                    .form-wizard
                        #step1(v-show="step === 1")
                            .form-group
                                label.star-required(for="receiver") {{'transfers.create.select.department.RECEIVER'|translate}}
                                .controls
                                    OptiSelectDepartment#receiver(
                                        :department-id="model.receiver"
                                        :filter="departments => departments.filter(d => d.type)"

                                        @selected="updateReceiver")
                                    label.warn(for="receiver" v-show="!model.receiver") {{'validation.formValidate.required'|translate}}

                            OptiTransfersLock(style="margin-bottom:15px" :alerts="lock.alerts")

                            .form-group
                                label.star-required(for="product_id") {{'transfers.create.PRODUCTID'|translate}}
                                .controls
                                    OptiSelectProduct#product_id(:product="model.product"
                                        @selected="p => model.product = p")
                                    label.warn(for="product_id" v-show="!model.product") {{'validation.formValidate.required'|translate}}

                                    .alert.alert-danger(style="margin-top:10px;margin-bottom:0" v-show="errors.length")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'transfers.create.product_validate.HEADER'|translate}}
                                        ul(style="list-style-type:square")
                                            li(v-for="error in errors") {{error}}

                        #step2(v-show="step === 2")
                            .form-group
                                label.star-required(for="sender") {{'transfers.create.select.department.SENDER'|translate}}
                                .controls
                                    select#sender.form-control(v-model="model.sourceDepartment" name="sender"
                                        :disabled="model.senders.length === 1" required)
                                        option(v-for="sender in model.senders" :key="sender.departmentId"
                                            :value="sender") {{sender.department.name}} ({{sender.stock}})
                                    label.warn(for="sender" v-show="!model.sourceDepartment") {{'validation.formValidate.required'|translate}}

                                    .alert.alert-danger(style="margin-top:20px;margin-bottom:0" v-show="senderLock.header")
                                        i.fa.fa-exclamation-circle.mr3
                                        span(v-html="senderLock.header")
                                        ul(style="list-style-type:square")
                                            li(v-for="message in senderLock.messages" v-html="message")

                        #step3(v-if="step === 3")
                            .form-group
                                label.star-required(for="reason") {{'transfers.create.select.REASON'|translate}}
                                .control(style="padding-bottom:10px")
                                    select#reason.form-control(:disabled="model.sourceDepartment.types.length === 1"
                                        v-model="model.reason" @change="updateModelReason")
                                        option(v-for="documentType in model.sourceDepartment.types" :value="documentType.type.id") {{documentType.type.name}}
                                    label.warn(for="reason" v-show="model.reason === null") {{'validation.formValidate.required'|translate}}

                            .form-group(v-if="model.reason !== null && model.sourceDepartment.types.filter(d => d.type.id === model.reason)[0].ids.length > 0")
                                label.star-required(for="reasonId") {{'transfers.create.select.REASON_ID'|translate}}
                                .control(style="padding-bottom:10px")
                                    select#reasonId.form-control(:disabled="model.sourceDepartment.types.filter(d => d.type.id === model.reason)[0].ids.length === 1"
                                        v-model="model.reasonId")
                                        option(v-for="documentTypeId in model.sourceDepartment.types.filter(d => d.type.id === model.reason)[0].ids"
                                            :value="documentTypeId") {{documentTypeId}}
                                    label.warn(for="reasonId" v-show="!model.reasonId") {{'validation.formValidate.required'|translate}}

                            .form-group
                                label.star-required(for="comments") {{'transfers.create.COMMENT'|translate}}
                                .control
                                    textarea#comments.form-control(v-model="model.comments" name="comment" required)
                                    label.warn(for="comments" v-show="!model.comments") {{'validation.formValidate.required'|translate}}

                        #step4(v-if="step === 4")
                            .row
                                .col-xs-6
                                    .form-group
                                        label {{'transfers.create.details.PRODUCTID'|translate}}
                                        .controls
                                            OptiProduct(:product="model.product") {{model.product.id}}
                                    .form-group
                                        label {{'transfers.create.details.PRODUCTNAME'|translate}}
                                        .controls
                                            OptiProduct(:product="model.product") {{model.product.name}}
                                .col-xs-6
                                    a.link(:href="$state.href('app.catalog.product', {id: model.product.id, departmentId: model.sourceDepartment.department.id})"
                                        target="_blank" :title="'transfers.create.details.title.PRODUCTDETAILS'|translate")
                                        img.img-responsive(:src="'thumb/product/ids/' + model.product.id + '.jpg'")

                            .row
                                .col-xs-12
                                    .form-group
                                        label {{'transfers.create.select.department.SENDER'|translate}}
                                        .controls {{model.sourceDepartment.department.name}}
                                    .form-group
                                        label {{'transfers.create.select.department.RECEIVER'|translate}}
                                        .controls {{model.receiver.name}}
                            .row
                                .col-xs-6
                                    .form-group
                                        label {{'transfers.create.details.QUANTITY'|translate}}
                                        .controls 1
                                .col-xs-6
                                    .form-group
                                        label {{'transfers.create.details.REASON'|translate}}
                                        .controls
                                            OptiEnum.mr3(enum="TransferDocumentType" :id="model.reason")
                                            OptiRefId(section="transfer" :type-id="model.reason" :id="model.reasonId")
                            .row
                                .col-xs-12
                                    .form-group
                                        label {{'transfers.create.details.COMMENT'|translate}}
                                        .controls(v-html="$options.filters.nl2br(model.comments)")

                .panel-footer(v-show="step === 1")
                    OptiButton(v-show="permission_admin" :title="'sidebar.TRANSFERS_LOCK'|translate"
                        @click.native="$state.go('app.warehouse.transfer.lock')") {{'sidebar.TRANSFERS_LOCK'|translate}}
                        template(#icon)
                            i.fa.fa-ban

                    OptiButton.pull-right(type="success" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="!model.receiver || !model.product" @click.native="selectProduct"
                        ) {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                    .clearfix

                .panel-footer(v-show="step === 2")
                    OptiButton(:title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 1") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton.pull-right(type="success" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="!model.sourceDepartment || !!senderLock.header"
                        @click.native="selectDepartment") {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                .panel-footer(v-if="step === 3")
                    OptiButton(:title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 2") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButton.pull-right(type="success" :title="'transfers.create.action.NEXTTITLE'|translate" :right="true"
                        :disabled="disabledStep3" @click.native="step = 4") {{'transfers.create.action.NEXT'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-right

                .panel-footer(v-show="step === 4")
                    OptiButton(:title="'transfers.create.action.BACKTITLE'|translate"
                        @click.native="step = 3") {{'transfers.create.action.BACK'|translate}}
                        template(#icon)
                            i.fa.fa-arrow-left

                    OptiButtonSave.pull-right(:title="'transfers.create.action.CREATETITLE'|translate"
                        @click.native="create")

                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
    import OptiTransfersLock from '@/js/app/vue/components/Warehouse/Transfers/Lock/OptiTransfersLock'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTransferCreate',
        components: {
            OptiSelectDepartment,
            OptiTransfersLock,
            OptiSelectProduct,
            OptiButton,
            OptiSelectEnum,
            OptiProduct,
            OptiEnum,
            OptiRefId,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                model: {
                    receiver: this.$uac.user.departmentType ? this.$uac.user.activeDepartmentId : null,
                    product: null,
                    senders: [],
                    sourceDepartment: null,
                    reason: null,
                    reasonId: 0,
                    comments: ''
                },

                lock: {
                    lock: true,
                    alerts: [],
                    available_products: {
                        ids: {},
                        types: {}
                    }
                },

                permission_admin: this.$uac.permission('transfers.lock.allow'),
                step: 1,
                errors: [],
                loading: false
            }
        },
        methods: {
            updateReceiver(department) {
                this.model.receiver = department

                this.checkLock()
            },
            checkLock() {
                this.loading = true

                this.model.product = null

                API.get('transfer/lock/' + this.model.receiver.id).then(res => {
                    this.lock = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.lock.notify.error.LOCK')
                })
            },
            selectProduct() {
                this.model.senders = []
                this.model.sourceDepartment = null

                this.model.reason = null
                this.model.reasonId = 0

                this.errors = []
                this.loading = true

                API.post('warehouse/transfers/product-validate', {
                    department_id: this.model.receiver.id,
                    product_id: this.model.product.id
                }).then(res => {
                    this.errors = res.data.errors

                    if(!this.errors.length) {
                        this.model.senders = res.data.senders
                        this.model.sourceDepartment = this.model.senders[0]

                        this.step = 2
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.create.notify.error.PRODUCT_VALIDATE')
                })
            },
            selectDepartment() {
                if(this.model.sourceDepartment) {
                    this.model.reason = null
                    this.model.reasonId = 0

                    if(this.model.sourceDepartment.types.length === 1) {
                        this.model.reason = this.model.sourceDepartment.types[0].type.id

                        this.updateModelReason(this.model.sourceDepartment.types[0].type.id)
                    }

                    this.step = 3
                }
            },

            updateModelReason() {
                let ids = this.model.sourceDepartment.types.filter(d => d.type.id === this.model.reason)[0].ids

                this.model.reasonId = ids.length === 1 ? ids[0] : 0
            },

            create() {
                this.loading = true

                API.post('warehouse/transfers/create', {
                    model: this.model,
                    alerts: this.lock.alerts
                }).then(() => {
                    this.$state.go('app.warehouse.transfers.incoming.ready')

                    this.$notify.success('transfers.create.notify.CREATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.create.notify.error.CREATE')
                })
            },
        },
        computed: {
            disabledStep3() {
                return this.model.reason === null ||
                    (!!this.model.sourceDepartment.types.filter(d => d.type.id === this.model.reason)[0].ids.length && !this.model.reasonId) ||
                    !this.model.comments
            },
            senderLock() {
                if(this.model.sourceDepartment) {
                    let selected = _.find(this.model.senders, {department: {id: this.model.sourceDepartment.departmentId}})

                    if(typeof selected !== 'undefined' && selected.lock_message) {
                        return selected.lock_message
                    }
                }

                return {
                    header: '',
                    messages: [],
                }
            }
        }
    }
</script>