<template lang="pug">
    .panel.panel-transparent
        .panel-heading
            i.fa.fa-briefcase.fa-fw.text-muted.mr3
            span.text-muted {{'dashboard.simple.offer.HEADING'|translate}}

        .action-div(style="font-size:26px")
            OptiReportOffersRate(:user-id="$uac.user.id")

            OptiButton(style="display:block;margin:20px auto 0" @click.native="$state.go('app.reports.offers')"
                ) {{'dashboard.simple.offer.BTN'|translate}}
                template(#icon)
                    i.fa.fa-briefcase
</template>

<script>
    import OptiReportOffersRate from '@/js/app/vue/components/Reports/Offers/OptiReportOffersRate'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReportOffersRateDashboard',
        components: {
            OptiReportOffersRate,
            OptiButton,
        }
    }
</script>