<template lang="pug">
    div
        .row(v-show="!loading")
            .col-xs-12.text-right(style="padding-bottom:10px" v-show="permission_interview_create")
                OptiButton(type="success" :disabled="block"
                    @click.native="interviewCreate()") {{'optometrist.patient.confirm.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-plus

                    MountingPortal(v-if="dialog_create" mountTo="#vue-modal" append)
                        OptiDialogConfirm(text="optometrist.action.create"
                            @confirmed="interviewCreateConfirmed" @closed="dialog_create = false")

            .col-xs-12(v-show="interviews.length")
                .table-responsive
                    table.table.table-striped
                        thead
                            tr
                                th Id
                                th {{'optometrist.patient.DATA'|translate}}
                                th {{'optometrist.patient.USER'|translate}}
                                th {{'optometrist.STATUS'|translate}}
                                th
                        tbody
                            tr(v-for="interview in interviews")
                                td
                                    a.link(:href="$state.href('app.optometrist.details', {id: interview.id})"
                                        :title="'default.REFERENCETYPE'|translate") {{interview.id}}
                                    OptiTaskIcon(style="margin-left:3px" v-if="interview.notes.length" name="notes")
                                td {{interview.date}}
                                td {{interview.doctor_name}}
                                td {{interview.status_name|translate}}
                                td.text-center
                                    a(:href="$state.href('app.optometrist.details', {id: interview.id})"
                                        :title="'default.REFERENCETYPE'|translate")
                                        i.fa.fa-info-circle.fa-2x.fa-fw

            .col-xs-12(v-show="!interviews.length")
                .alert.alert-warning.text-center
                    i.fa.fa-exclamation-circle.mr3
                    span {{'optometrist.patient.notify.NOINTERVIEW'|translate}}

        OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {Activity} from '@/js/app/vue/helpers/Activity'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskIcon from '../../Tasks/OptiTaskIcon'
    import OptiDimmer from '../../Blocks/OptiDimmer'
    import OptiDialogConfirm from '@/js/app/vue/components/Button/Dialogs/OptiDialogConfirm'

    export default {
        name: 'OptiPatientsDetailsInterviews',
        components: {
            OptiDialogConfirm,
            OptiButton,
            OptiTaskIcon,
            OptiDimmer
        },
        props: {
            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                interviews: [],

                dialog_create: false,
                permission_interview_create: this.$uac.permission('optometrist.create'),
                loading: true
            }
        },
        mounted() {
            API.get('patient/interview/fromPatientId/' + this.$state.params.id).then(res => {
                this.interviews = res.data

                this.$emit('update-count', 'interviews', this.interviews.length)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('optometrist.patient.notify.ERRORINTERVIEW')
            })
        },
        methods: {
            findOldestActive() {
                let _return = 0

                for(let i = 0; i < this.interviews.length - 1; ++i) {
                    if(this.interviews[i].status === 1) {
                        _return = this.interviews[i].id

                        break
                    }
                }

                return _return
            },
            interviewCreate() {
                let interview_id = this.findOldestActive()

                if(interview_id) {
                    this.$state.go('app.optometrist.details', {
                        id: interview_id
                    })
                } else {
                    this.dialog_create = true
                }
            },
            interviewCreateConfirmed() {
                this.dialog_create = false
                this.loading = true

                API.get('optometrist/interview/generate/' + this.$state.params.id + '/0').then(res => {
                    this.$state.go('app.optometrist.details', {id: res.data.id})

                    Activity.increaseForPatient(this.$state.params.id)

                    this.$notify.success('optometrist.notify.CREATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('optometrist.notify.error.CREATE')
                })
            }
        }
    }
</script>
