<template lang="pug">
    div
        label.warn(v-for="(params, name) in data.$params" :for="f" v-show="!data.$pending && !data[name]") {{text(name, params)}}
        div(v-show="data.$pending")
            i.fa.fa-cog.fa-spin.mr3
            span {{'default.PENDING'|translate}}
</template>

<script>
    export default {
        name: 'OptiValidate',
        props: {
            f: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            paymentDiff: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                prefix: 'validation.formValidate.'
            }
        },
        methods: {
            text(name, param) {
                switch(name) {
                    case 'required':
                    case 'noZero':
                    case 'noZeroIf':
                        return this.$filters.translate(this.prefix + 'required')

                    case 'minLength':
                    case 'minLengthIf':
                        return this.$filters.translate(this.prefix + 'minlength.PART1') + ' ' + param.min + ' ' +
                            this.$filters.translate(this.prefix + 'minlength.PART2')
                    case 'maxLength':
                        return this.$filters.translate(this.prefix + 'maxlength.PART1') + ' ' + param.max + ' ' +
                            this.$filters.translate(this.prefix + 'maxlength.PART2')
                    case 'equalLength':
                    case 'equalLengthIf':
                        return this.$filters.translate(this.prefix + 'equallength.PART1') + ' ' +
                            param.length + ' '  + this.$filters.translate(this.prefix + 'equallength.PART2')
                    case 'betweenLength':
                        return this.$filters.translate(this.prefix + 'fromtolength.PART1') + ' ' + param.min + ' ' +
                            this.$filters.translate(this.prefix + 'fromtolength.PART2') + ' ' + param. max + ' ' +
                            this.$filters.translate(this.prefix + 'fromtolength.PART3')

                    case 'unique':
                    case 'unique_lp':
                    case 'barcode':
                        return this.$filters.translate(this.prefix + 'exists')

                    case 'email':
                        return this.$filters.translate(this.prefix + 'email')

                    case 'numeric':
                        return this.$filters.translate(this.prefix + 'number')

                    case 'url':
                        return this.$filters.translate(this.prefix + 'url')

                    case 'sameAsPassword':
                        return this.$filters.translate(this.prefix + 'equal')

                    case 'duplicateConstructions':
                        return this.$filters.translate(this.prefix + 'duplicate')

                    case 'paymentMixRequiredMinTwoTypePayments':
                        return this.$filters.translate('payment.directive.validate.DOUBLE')

                    case 'paymentValueDifference':
                        return this.$filters.translate('payment.directive.validate.LEFT') + ' ' +
                            this.$filters.currency(this.paymentDiff) + ' ' +
                            this.$filters.translate('payment.directive.validate.TOENTRANCE')

                    case 'pesel':
                        return this.$filters.translate(this.prefix + 'pesel')

                    case 'dateCompare':
                        return [this.$filters.translate(this.prefix + 'dateCompare.PART1'),
                            this.$filters.translate(this.prefix + 'dateCompare.operators.' + param.operator.toUpperCase()),
                            this.$filters.translate(this.prefix + 'dateCompare.PART2'),
                            param.date_compare
                        ].join(' ') + '.'

                    case 'dateTodayOrFuture':
                        return this.$filters.translate(this.prefix + 'dateTodayOrFuture.' + (param.today ? 'TODAY' : 'FUTURE'))
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    label.warn {
        display: block;
    }
</style>
