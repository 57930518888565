<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-if="!loading")
            form#form-role.form-horizontal.form-validate(name="formRole" @submit.prevent="addException")
                .form-group
                    label.col-sm-4.control-label.star-required(for="user") {{'role.exceptions.table.USER'|translate}}
                    .col-sm-8
                        OptiSelectEmployeeGroupByDepartment#user(mode="central"
                            :user="exception.user" @selected="u => exception.user = u")
                        OptiValidate(f="user" :data="$v.exception.user.id")
                .form-group
                    label.col-sm-4.control-label.star-required(for="action") {{'role.exceptions.table.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#action(enum="UserPermissionOverrideType"
                            :value="exception.type" @update-enum="updateType")
                .form-group
                    label.col-sm-4.control-label.star-required(for="permission") {{'role.exceptions.table.PERMISSION'|translate}}
                    .col-sm-8
                        OptiSelectPermissions#permission(
                            :permission="exception.permission" @selected="p => exception.permission = p")
                        OptiValidate(f="permission" :data="$v.exception.permission.id")

                .form-group(v-show="!exception.type")
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-info.inline
                                label
                                    input(type="checkbox" name="temporary_lock" v-model="exception.temporary_lock.checkbox"
                                        @change="updateTemporaryLockCheckbox")
                                    span.fa.fa-check
                            span {{'role.exceptions.table.temporary_lock.CHECKBOX'|translate}}
                div(v-show="exception.temporary_lock.checkbox")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="started_at"
                            ) {{'role.exceptions.table.temporary_lock.date.STARTED_AT'|translate}}
                        .col-sm-8
                            OptiDate#started_at(:value="exception.temporary_lock.date.started_at"
                                @update-date="d => exception.temporary_lock.date.started_at = d")
                            OptiValidate(f="started_at" :data="$v.exception.temporary_lock.date.started_at")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="ended_at"
                            ) {{'role.exceptions.table.temporary_lock.date.ENDED_AT'|translate}}
                        .col-sm-8
                            OptiDate#ended_at(:value="exception.temporary_lock.date.ended_at"
                                @update-date="d => exception.temporary_lock.date.ended_at = d")
                            OptiValidate(f="ended_at" :data="$v.exception.temporary_lock.date.ended_at")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.exception.$invalid")

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {noZero, dateCompare, dateTodayOrFuture}  from '@/js/vue.validators.js'
    import {requiredIf} from 'vuelidate/lib/validators'

    import OptiSelectEmployeeGroupByDepartment from '@/js/app/vue/components/Select/OptiSelectEmployeeGroupByDepartment'
    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiSelectPermissions from '@/js/app/vue/components/Select/OptiSelectPermissions'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiRoleExceptionAdd',
        components: {
            OptiSelectEmployeeGroupByDepartment,
            OptiSelectEnum,
            OptiSelectPermissions,
            OptiValidate,
            OptiDate,
            OptiButtonSave,
            OptiDimmer
        },
        data() {
            return {
                exception: {
                    user: {
                        id: 0
                    },
                    type: 0,
                    temporary_lock: {
                        checkbox: false,
                        date: {
                            started_at: '',
                            ended_at: ''
                        }
                    },
                    permission: {
                        id: 0
                    }
                },

                loading: false
            }
        },
        validations() {
            return {
                exception: {
                    user: {
                        id: {
                            noZero
                        }
                    },
                    temporary_lock: {
                        date: {
                            started_at: {
                                required: requiredIf(() => this.exception.temporary_lock.checkbox),
                                dateCompare: dateCompare('lte', this.exception.temporary_lock.date.ended_at),
                                dateTodayOrFuture: dateTodayOrFuture(true)
                            },
                            ended_at: {
                                required: requiredIf(() => this.exception.temporary_lock.checkbox),
                                dateCompare: dateCompare('gte', this.exception.temporary_lock.date.started_at),
                                dateTodayOrFuture: dateTodayOrFuture(true)
                            }
                        }
                    },
                    permission: {
                        id: {
                            noZero
                        }
                    }
                }
            }
        },
        methods: {
            updateType(t) {
                this.exception.type = t

                if (this.exception.type) {
                    this.exception.temporary_lock.checkbox = false

                    this.updateTemporaryLockCheckbox()
                }
            },
            updateTemporaryLockCheckbox() {
               this.exception.temporary_lock.date = {
                   started_at: '',
                   ended_at: ''
               }
            },
            addException() {
                this.loading = true

                API.post('roles/exceptions/add', this.exception).then(() => {
                    this.$state.go('app.roles.exceptions')

                    this.$notify.success('role.exceptions.notify.SAVE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('role.exceptions.notify.error.SAVE')
                })
            }
        }
    }
</script>