<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-retweet.mr3
            span {{'transfers.lock.pre.BTN'|translate}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed')")

        template(#body)
            .row(v-if="data.product")
                .col-xs-6
                    .form-group
                        label {{'transfers.create.details.PRODUCTID'|translate}}
                        .controls
                            OptiProduct(:product="data.product") {{data.product.id}}
                    .form-group
                        label {{'transfers.create.details.PRODUCTNAME'|translate}}
                        .controls
                            OptiProduct(:product="data.product") {{data.product.name}}
                .col-xs-6
                    a.link(:href="$state.href('app.catalog.product', {id: data.product.id, departmentId: data.receiver.id})"
                        target="_blank" :title="'transfers.create.details.title.PRODUCTDETAILS'|translate")
                        img.img-responsive(:src="'thumb/product/ids/' + data.product.id + '.jpg'")
            .row
                .col-xs-12
                    .form-group
                        label.star-required(for="sender") {{'transfers.create.select.department.SENDER'|translate}}
                        .controls
                            select#sender.form-control(v-model="data.sender" name="sender"
                                :disabled="data.senders.length === 1" required)
                                option(v-for="sender in data.senders" :key="sender.departmentId"
                                    :value="sender") {{sender.department.name}} ({{sender.stock}})
                            label.warn(for="sender" v-show="!data.sender") {{'validation.formValidate.required'|translate}}

                            .alert.alert-danger(style="margin-top:20px;margin-bottom:0" v-if="data.sender && data.sender.lock_message.header")
                                i.fa.fa-exclamation-circle.mr3
                                span(v-html="data.sender.lock_message.header")
                                ul(style="list-style-type:square")
                                    li(v-for="message in data.sender.lock_message.messages" v-html="message")
                    .form-group
                        label {{'transfers.create.select.department.RECEIVER'|translate}}
                        .controls {{data.receiver.name}}
            .row
                .col-xs-6
                    .form-group
                        label {{'transfers.create.details.QUANTITY'|translate}}
                        .controls 1
                .col-xs-6
                    .form-group
                        label {{'transfers.create.details.REASON'|translate}}
                        .controls
                            OptiEnum.mr3(enum="TransferDocumentType" :id="data.ref.type")
                            OptiRefId(section="transfer" :type-id="data.ref.type" :id="data.ref.id")
            .row
                .col-xs-12
                    .form-group
                        label.star-required(for="comments") {{'transfers.create.details.COMMENT'|translate}}
                        .controls
                            textarea#comments.form-control(v-model="data.comments" name="comments" required)
                            label.warn(for="comments" v-show="!data.comments") {{'validation.formValidate.required'|translate}}

                .col-xs-12(v-show="data.errors.length")
                    .alert.alert-danger(style="margin-top:10px;margin-bottom:0")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'transfers.create.product_validate.HEADER'|translate}}
                        ul(style="list-style-type:square")
                            li(v-for="error in data.errors") {{error}}

                OptiDimmer(:active="loading")

        template(#footer)
            OptiButton.pull-left(type="default" @click.native="$emit('closed')") {{'default.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButtonSave(:title="'transfers.create.action.CREATETITLE'|translate"
                :disabled="$v.data.$invalid || !!(data.sender && data.sender.lock_message.header) || !!data.errors.length || loading"
                @click.native="create")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {required} from 'vuelidate/lib/validators'

    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiDialogButtonTransfer',
        components: {
            OptiProduct,
            OptiDialog,
            OptiButton,
            OptiEnum,
            OptiRefId,
            OptiButtonSave,
            OptiDimmer,
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        },
        validations: {
            data: {
                sender: {
                    required
                },
                comments: {
                    required
                }
            }
        },
        methods: {
            create() {
                this.loading = true

                let parsedData = {
                    sourceDepartment: {
                        department: {
                            id: this.data.sender.department.id,
                            name: this.data.sender.department.name
                        }
                    },
                    receiver: {
                        id: this.data.receiver.id,
                        name: this.data.receiver.name
                    },
                    product: {
                        id: this.data.product.id,
                        name: this.data.product.name
                    },
                    comments: this.data.comments,
                    reason: this.data.ref.type,
                    reasonId: this.data.ref.id
                }

                API.post('warehouse/transfers/create', {
                    model: parsedData,
                    simple: true
                }).then(() => {
                    this.$emit('created')

                    this.$notify.success('transfers.create.notify.CREATE')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.create.notify.error.CREATE')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 460px;
    }
</style>