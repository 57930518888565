<template lang="pug">
    span(style="display:inline-block;position:relative")
        OptiButton(type="primary" v-if="data.exists.id"
            @click.native="$state.go('app.warehouse.transfer.detail', {id: data.exists.id})"
            ) {{data.exists.header}} {{data.exists.id}}
            template(#icon)
                i.fa.fa-retweet

        button.btn(class="btn-labeled btn-primary" v-else :disabled="loading" @click="open = true")
            span.btn-label
                i.fa.fa-retweet
            span {{'transfers.lock.pre.BTN'|translate}}
            span.btn-label.btn-label-right(v-show="!loading")
                i.fa(:class="{'fa-ban': ban, 'fa-check': !ban}")

        OptiDimmer(:active="loading")

        MountingPortal(v-if="open" mountTo="#vue-modal" append)
            OptiDialogButtonTransfer(
                :data="data.create"

                @created="open = false;load()"
                @closed="open = false")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiDialogButtonTransfer from '@/js/app/vue/components/Button/Dialogs/OptiDialogButtonTransfer'

    export default {
        name: 'OptiButtonTransfer',
        components: {
            OptiButton,
            OptiDimmer,
            OptiDialogButtonTransfer,
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            id: {
                type: Number,
                required: false,
                default: 0,
            },
        },
        data() {
            return {
                data: {
                    exists: {
                        header: '',
                        id: 0
                    },
                    create: {
                        senders: [],
                        errors: [],
                        ref: {
                            type: 0,
                            id: 0
                        }
                    }
                },

                open: false,
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                API.get('warehouse/transfers/button/create/' + this.type + '/' + this.id).then(res => {
                    this.data = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('transfers.lock.pre.notify.error.INFO')
                })
            }
        },
        computed: {
            ban() {
                return !this.data.create.senders.length || !!this.data.create.errors.length
            }
        }
    }
</script>