import { render, staticRenderFns } from "./OptiRoleExceptionDashboard.vue?vue&type=template&id=2b071b9a&lang=pug&"
import script from "./OptiRoleExceptionDashboard.vue?vue&type=script&lang=js&"
export * from "./OptiRoleExceptionDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports