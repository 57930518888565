<template lang="pug">
    div
        div(v-for="alert in alerts")
            .header(v-html="alert.header")
            .text(v-show="toggle" v-html="alert.text")
</template>

<script>
    export default {
        name: 'OptiTransfersLock',
        props: {
            alerts: {
                type: Array,
                required: true
            },
            toggle: {
                type: Boolean,
                required: false,
                default: true
            }
        }
    }
</script>