<template lang="pug">
    .row
        .col-xs-6(v-if="!loading")
            OptiButtonHistory(data="transfer" :id="transfer.id")

        .col-xs-6.text-right(v-if="!loading")
            OptiButtonTransfer(type="return_transfer" :id="transfer.id")

        .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0(v-if="!loading")
            .panel-grid
                .panel.panel-primary
                    .panel-heading
                        .pull-left
                            i.fa.fa-navicon.mr3
                            span {{'transfers.details.header.TRANSFER'|translate}}
                        .pull-right \#{{transfer.id}}
                        .clearfix

                    .row
                        .col-sm-6 {{'transfers.details.table.SENDER'|translate}}
                        .col-sm-6
                            div(v-show="selectShow")
                                select.form-control(style="display:inline-block;width:95%" v-model="transfer.sender.id"
                                    :disabled="products.length === 1" required)
                                    option(v-for="product in products" :value="product.department.id") {{product.department.name + ' (' + product.stock + ')'}}
                                .bold.warn(v-show="!transfer.sender.id") {{'validation.formValidate.required'|translate}}
                            span(v-show="!selectShow") {{transfer.sender.name}}
                        .clearfix

                    .alert.alert-danger(v-show="lock.header")
                        i.fa.fa-exclamation-circle.mr3
                        span(v-html="lock.header")
                        ul(style="list-style-type:square")
                            li(v-for="message in lock.messages" v-html="message")

                    .row
                        .col-sm-6 {{'transfers.details.table.RECEIVER'|translate}}
                        .col-sm-6 {{transfer.receiver.name}}
                    .row
                        .col-sm-6 {{'transfers.details.table.PRODUCTID'|translate}}
                        .col-sm-6
                            OptiProduct(v-if="transfer.productId > 0" :product="transfer.product") {{transfer.productId}}
                            span(v-show="transfer.productId <= 0") {{transfer.productId}}
                    .row
                        .col-sm-6 {{'transfers.details.table.PRODUCTNAME'|translate}}
                        .col-sm-6
                            OptiProduct(v-if="transfer.productId > 0" :product="transfer.product") {{transfer.productName}}
                            span(v-show="transfer.productId <= 0") {{transfer.productName}}
                    .row
                        .col-sm-6 {{'transfers.details.table.QUANTITY'|translate}}
                        .col-sm-6 {{transfer.quantity}}
                    .row
                        .col-sm-6 {{'transfers.details.table.STATUS'|translate}}
                        .col-sm-6 {{transfer.statusName|translate}}
                    .row(v-show="transfer.comments")
                        .col-sm-6 {{'transfers.details.table.COMMENTS'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(transfer.comments)")
                    .row
                        .col-sm-6 {{'transfers.details.table.CREATEDAT'|translate}}
                        .col-sm-6 {{transfer.created}}
                    .row(v-show="transfer.status === 3")
                        .col-sm-6 {{'transfers.details.table.RECEIVEDAT'|translate}}
                        .col-sm-6 {{transfer.finished}}
                    .row(v-show="transfer.user.id")
                        .col-sm-6 {{'transfers.details.table.CREATEDBY'|translate}}
                        .col-sm-6 {{transfer.user.firstName}} {{transfer.user.lastName}}
                    .row
                        .col-sm-6 {{'transfers.details.table.REFERENCE'|translate}}
                        .col-sm-6
                            OptiEnum.mr3(enum="TransferDocumentType" :id="transfer.refType")
                            OptiRefId(section="transfer" :type-id="transfer.refType" :id="transfer.refId")

                    .alert.alert-danger(v-show="transfer.task_cancelled")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'transfers.details.notify.TASKCANCELLED'|translate}}

                    .row(v-show="transfer.reason_cancelled")
                        .col-sm-6 {{'transfers.details.table.REASONCANCELLED'|translate}}
                        .col-sm-6(v-html="$options.filters.nl2br(transfer.reason_cancelled)")
                    .row(v-show="shipment_exists")
                        .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                        .col-sm-6
                            OptiShipmentNotice(:type="13" :id="transfer.id" @shipment-exists="s => shipment_exists = s")

                    .panel-footer(v-opti-empty)
                        OptiButton(type="danger" :title="'transfers.details.action.cancel.BTN'|translate|lower"
                            v-if="$uac.permission('transfers.cancel') && (transfer.status === 0 || transfer.status === 1 || transfer.status === 2)"
                            @click.native="dialog_cancel = true") {{'transfers.details.action.cancel.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-remove

                            MountingPortal(v-if="dialog_cancel" mountTo="#vue-modal" append)
                                OptiTransferDetailsDialogReasonCancel(
                                    @confirmed="goCancel"
                                    @closed="dialog_cancel = false")

                        OptiButton.pull-right(type="success" :title="'transfers.details.action.accept.BTN'|translate|lower"
                            v-if="$uac.permission('transfers.accept') && !transfer.status" :disabled="selectShow && (!transfer.sender.id || !!lock.header)"
                            text="transfers.details.action.accept.confirm" @confirmed="goAccept") {{'transfers.details.action.accept.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check

                        OptiButton.pull-right(type="success" :title="'transfers.details.action.receive.BTN'|translate"
                            v-if="transfer.status === 2 && transfer.destinationSalonId === $uac.user.activeDepartmentId"
                            :disabled="transfer.task_cancelled" text="transfers.details.action.receive.confirm" @confirmed="goReceive"
                            ) {{'transfers.details.action.receive.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-check

            OptiNotes(table="transfer_notes" table-key="transfer_id" :id="transfer.id" :notes="transfer.notes"
                @update-notes="n => transfer.notes = n")

        .col-xs-12
            OptiDimmer(:active="loading")

</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiEnum from '@/js/app/vue/components/OptiEnum'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTransferDetailsDialogReasonCancel
        from '@/js/app/vue/components/Warehouse/Transfers/Details/Dialogs/OptiTransferDetailsDialogReasonCancel'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButtonTransfer from "@/js/app/vue/components/Button/OptiButtonTransfer.vue";

    export default {
        name: 'OptiTransferDetails',
        components: {
            OptiButtonTransfer,
            OptiButtonHistory,
            OptiProduct,
            OptiEnum,
            OptiRefId,
            OptiShipmentNotice,
            OptiButton,
            OptiTransferDetailsDialogReasonCancel,
            OptiNotes,
            OptiDimmer
        },
        data() {
            return {
                transfer: {},

                products: [],
                shipment_exists: false,

                dialog_cancel: false,
                loading: true
            }
        },
        mounted() {
            API.get('warehouse/transfer/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).catch(() => {
                this.$notify.error('transfers.details.notify.error.LOAD')
            })
        },
        methods: {
            updateBreadcrumb() {
                let change = [],
                    outcoming = this.transfer.sourceSalonId === this.$uac.user.activeDepartmentId

                change.push({
                    id: 3,
                    field: 'name',
                    value: 'transfers.breadcrumb.' + (outcoming ? 'OUTCOMING' : 'INCOMING')
                }, {
                    id: 4,
                    field: 'functions',
                    value: outcoming ? 'outcomingtransfers' : 'incomingtransfers'
                }, {
                    id: 4,
                    field: 'args',
                    value: this.transfer.statusName
                })

                this.$emit('update-breadcrumb', change)
            },
            getData(data) {
                this.transfer = data
                this.updateBreadcrumb()

                if(!this.transfer.status) {
                    API.post('warehouse/transfers/product-validate', {
                        department_id: this.transfer.receiver.id,
                        product_id: this.transfer.product.id,
                        excepted_transfer_id: this.transfer.id
                    }).then(res => {
                        this.products = res.data.senders

                        let _exists = false

                        this.products.forEach(product => {
                            if(product.department.id === this.transfer.sender.id) {
                                _exists = true
                            }
                        })

                        if(!_exists) {
                            this.transfer.sender.id = this.products.length === 1 ?
                                this.products[0].department.id : null
                        }
                    }).finally(() => {
                        this.loading = false
                    }).catch(() => {
                        this.transfer.sender.id = null

                        this.$notify.error('transfers.create.notify.error.PRODUCT_VALIDATE')
                    })
                } else {
                    this.loading = false
                }
            },

            goCancel(reason) {
                this.loading = true

                API.post('warehouse/transfer/cancel', {
                    id: this.transfer.id,
                    reason_cancelled: reason
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('transfers.details.notify.CANCEL')
                }).catch(() => {
                    this.$notify.error('transfers.details.notify.error.CANCEL')
                })
            },
            goAccept() {
                this.loading = true

                API.post('warehouse/transfer/accept', {
                    id: this.transfer.id,
                    department_source_id: this.transfer.sender.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('transfers.details.notify.ACCEPT')
                }).catch(() => {
                    this.$notify.error('transfers.details.notify.error.ACCEPT')
                })
            },
            goReceive() {
                this.loading = true

                API.post('warehouse/transfer/receive', {
                    id: this.transfer.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('transfers.details.notify.RECEIVE')
                }).catch(() => {
                    this.$notify.error('transfers.details.notify.error.RECEIVE')
                })
            }
        },
        computed: {
            selectShow() {
                return this.$uac.permission('transfers.accept') && !this.transfer.status
            },
            lock() {
                let selected = _.find(this.products, {department: {id: this.transfer.sender.id}})

                if(typeof selected !== 'undefined' && selected.lock_message) {
                    return selected.lock_message
                }

                return {
                    header: '',
                    messages: [],
                }
            }
        }

    }
</script>

<style lang="less" scoped>
    .alert {
        margin:5px 15px;
    }
</style>