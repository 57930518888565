<template lang="pug">
    div
        #btn-group-patient-detail.row(v-if="!loading")
            .col-sm-4.col-xs-12.text-center-xs
                OptiButtonHistory(data="reclamation_sale" :id="reclamation.id")
                OptiButtonPrint(style="margin-left:5px" :title="'sale.reclamation.print.warehouse.BTNTITLE'|translate"
                    v-show="department_id === 49 || department_id === 91"
                    url="sale/reclamation/print/control" :id="reclamation.id") {{'sale.reclamation.print.warehouse.BTN'|translate}}
            .col-sm-4.col-xs-12.text-center
                OptiButtonSms(v-show="reclamation.status > 3" :patient="sms.patient" :tags="sms.tags")
                OptiButton(type="primary" :title="'sale.reclamation.action.edit.TITLE'|translate" v-show="reclamation.status === 1"
                    @click.native="$state.go('app.sale.reclamation.edit', {id: reclamation.id})") {{'sale.reclamation.action.edit.BTN'|translate}}
                    template(#icon)
                        i.fa.fa-edit
            .col-sm-4.col-xs-12.text-center-xs.text-right
                OptiButtonPrint(:title="'sale.reclamation.print.department.BTNTITLE'|translate"
                    url="sale/reclamation/print" :id="reclamation.id") {{'sale.reclamation.print.department.BTN'|translate}}
                OptiButtonPrint(style="margin-left:5px" :title="'sale.reclamation.print.customer.BTNTITLE'|translate" v-show="reclamation.status >= 4"
                    url="sale/reclamation/print/customer" :id="reclamation.id") {{'sale.reclamation.print.customer.BTN'|translate}}

        .row(v-if="permission.admin && !loading")
            .col-xs-3
                a.btn.btn-success(:title="'sale.reclamation.navi.PREV'|translate" :disabled="!navi.prev"
                    :href="$state.href('app.sale.reclamation.details', {id: navi.prev})")
                    i.fa.fa-arrow-left
            .col-xs-6
                .text-center(style="display:flex;justify-content:space-around")
                    OptiButtonDepository(:product-id="reclamation.product_id")

                    OptiButtonTransfer(type="reclamation_sale" :id="reclamation.id")
            .col-xs-3.text-right
                a.btn.btn-success(:title="'sale.reclamation.navi.NEXT'|translate" :disabled="!navi.next"
                    :href="$state.href('app.sale.reclamation.details', {id: navi.next})")
                    i.fa.fa-arrow-right

        .row(v-if="!loading")
            .col-md-8.col-md-offset-2.col-xs-12.col-xs-offset-0
                .panel-grid
                    .panel.panel-primary
                        .panel-heading
                            i.fa.fa-navicon.mr3
                            span {{'sale.breadcrumb.RECLAMATION'|translate}} \#{{reclamation.id}}
                            .pull-right \#{{reclamation.data}}

                        .row(v-show="reclamation.date_of_notice !== '0000-00-00'")
                            .col-sm-6 {{'sale.reclamation.DATEOFNOTICE'|translate}}
                            .col-sm-6 {{reclamation.date_of_notice}}
                        .row
                            .col-sm-6 {{'sale.reclamation.details.EXPECTED'|translate}}
                            .col-sm-6 {{reclamation.expected|noYearSek}}
                        .row(v-show="reclamation.description")
                            .col-sm-6 {{'sale.reclamation.details.DESCRIPTION'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(reclamation.description)")
                        .row(v-show="reclamation.department_description")
                            .col-sm-6 {{'sale.reclamation.DEPARTMENTDESCRIPTION'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(reclamation.department_description)")
                        .row(v-show="reclamation.reference_type === 'sale'")
                            .col-sm-6 {{'sale.reclamation.details.SALEID'|translate}}
                            .col-sm-6
                                a.link(:title="'sale.reclamation.details.title.SALE'|translate"
                                    :href="$state.href('app.sale.details', {id: reclamation.reference})" target="_blank"
                                    ) {{reclamation.reference}}
                        .row(v-show="reclamation.sale.customerName")
                            .col-sm-6 {{'sale.reclamation.details.WHEREDEPARTMENT'|translate}}
                            .col-sm-6 {{reclamation.sale.customerName}}
                        .row
                            .col-sm-6 {{'sale.reclamation.details.PRODUCT'|translate}}
                            .col-sm-6(v-if="reclamation.productRelation.id")
                                OptiProduct(:product="reclamation.productRelation") {{reclamation.productRelation.name}}
                            .col-sm-6(v-show="!reclamation.productRelation.id") {{reclamation.product_name}}
                        .row(v-show="reclamation.sale.patient.id")
                            .col-sm-6 {{'sale.offstock.table.head.PATIENT'|translate}}
                            .col-sm-6
                                OptiPatient(:data="reclamation.sale.patient")
                        .row(v-show="!reclamation.sale.patient.id")
                            .col-sm-6 {{'sale.offstock.table.head.PATIENT'|translate}}
                            .col-sm-6 {{reclamation.client}}
                        .row(v-show="reclamation.street")
                            .col-sm-6 {{'sale.reclamation.ADRRESS'|translate}}
                            .col-sm-6 {{reclamation.street}}, {{reclamation.zip_code}} {{reclamation.city}}
                        .row(v-show="reclamation.cellphonenumber")
                            .col-sm-6 {{'sale.reclamation.TELEPHONE'|translate}}
                            .col-sm-6 {{reclamation.cellphonenumber|phone}}
                        .row(v-show="reclamation.user.id")
                            .col-sm-6 {{'sale.reclamation.details.CREATEDBY'|translate}}
                            .col-sm-6 {{reclamation.user.firstName}} {{reclamation.user.lastName}}
                        .row(v-show="reclamation.accepted !== -1")
                            .col-sm-6 {{'sale.reclamation.details.DECISION'|translate}}
                            .col-sm-6.bold
                                span.mr3(v-show="reclamation.accepted_previous !== -1")
                                    span.mr3(:class="{'warn': !reclamation.accepted_previous, 'text-success': reclamation.accepted_previous > 0}"
                                    ) {{reclamation.accepted_previous_name|translate}}
                                    i.fa.fa-arrow-right
                                span(:class="{'warn': !reclamation.accepted, 'text-success': reclamation.accepted > 0}"
                                    ) {{reclamation.accepted_name|translate}}
                        .row(v-show="reclamation.status === 7 && reclamation.accepted === 4")
                            .col-sm-6 {{'sale.reclamation.details.CHANGETO'|translate}}
                            .col-sm-6(style="padding-right:15px")
                                OptiSelectProduct#product-change(:product="model.product" @selected="updateProduct")
                                label.warn(style="display:block" for="product-change" v-show="!model.product.id"
                                    ) {{'validation.formValidate.required'|translate}}
                        .row(v-show="reclamation.accepted === 4 && model.product.id && model.depository.id && !model.depository.stock")
                            .col-xs-12
                                .alert.alert-warning(style="margin-right:15px;margin-bottom:0")
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{reclamation.department.name}} {{'sale.reclamation.PRODUCTNOSTOCK'|translate}}.
                        .row(v-show="reclamation.status === 7 && reclamation.accepted === 4 && model.product.id && reclamation.change_price < 0")
                            .col-sm-6 {{'sale.reclamation.details.CHANGETOADD'|translate}}
                            .col-sm-6 {{reclamation.change_price * -1|currency}}
                        .row(v-show="reclamation.status === 7 && reclamation.accepted === 4 && model.product.id && reclamation.change_price > 0")
                            .col-sm-6 {{'sale.reclamation.details.CHANGETOMINUS'|translate}}
                            .col-sm-6 {{reclamation.change_price|currency}}
                        .row(v-if="reclamation.changeProduct.id")
                            .col-sm-6 {{'sale.reclamation.details.CHANGEPRODUCT'|translate}}
                            .col-sm-6
                                OptiProduct(:product="reclamation.changeProduct") {{reclamation.changeProduct.name}}
                        .row(v-show="!(reclamation.status === 7 && reclamation.accepted === 4) && reclamation.change_price < 0")
                            .col-sm-6 {{'sale.reclamation.details.CHANGEDTOADD'|translate}}
                            .col-sm-6
                                span {{reclamation.change_price * -1|currency}}
                                span(v-if="noticeReclamation.all")
                                    OptiReceiptNoticeValue(:data="noticeReclamation.all")
                                    OptiReceiptNotice(style="display:block" :data="noticeReclamation.all")
                        .row(v-show="!(reclamation.status === 7 && reclamation.accepted === 4) && reclamation.change_price > 0")
                            .col-sm-6 {{'sale.reclamation.details.CHANGEDTOMINUS'|translate}}
                            .col-sm-6
                                span {{reclamation.change_price|currency}}
                                span(v-if="noticeReclamation.all")
                                    OptiReceiptNoticeValue(:data="noticeReclamation.all")
                                    OptiReceiptNotice(style="display:block" :data="noticeReclamation.all")
                        .row(style="padding-left:30px;padding-right:30px" v-if="reclamation.status === 7 && reclamation.accepted === 4")
                            .col-xs-12
                                form.form.form-horizontal
                                    OptiPayment(
                                        :payments="payment"
                                        :value="change_price_payment"

                                        :required="true"

                                        @invalid="i => payment_invalid = i")
                        OptiPaymentShow(v-show="reclamation.payment_type"
                            :type-id="reclamation.payment_type" :type-name="reclamation.payment_type_name"
                            :payments="reclamation.payment" :differences="noticeReclamation.differences")
                        .row(v-show="reclamation.finished")
                            .col-sm-6 {{'sale.reclamation.details.FINISHED'|translate}}
                            .col-sm-6 {{reclamation.finished}}
                        .row(v-show="reclamation.decision")
                            .col-sm-6 {{'sale.reclamation.details.DECISIONDESCRIPTION'|translate}}
                            .col-sm-6(v-html="$options.filters.nl2br(reclamation.decision)")
                        .row(v-show="shipment_exists")
                            .col-sm-6 {{'shipments.SHIPMENTS'|translate}}
                            .col-sm-6
                                OptiShipmentNotice(:type="14" :id="reclamation.id"
                                    @shipment-exists="s => shipment_exists = s")
                        .row(v-show="reclamation.proof_type")
                            .col-sm-6 {{'sale.reclamation.PROOF'|translate}}
                            .col-sm-6 {{reclamation.proof_type_name|translate}}
                        .row(v-show="reclamation.proof_type")
                            .col-sm-6 {{'sale.reclamation.PROOFID'|translate}}
                            .col-sm-6 {{reclamation.proof_id|receipt}}
                        .row(v-if="reclamation.proof_photo")
                            .col-sm-6 {{'sale.reclamation.PROOFPHOTO'|translate}}
                            .col-sm-6(style="padding-left:10px;padding-right:10px")
                                a(:href="'/sale/reclamation/proof/' + reclamation.proof_photo|upload" target="_blank"
                                    :title="'test.question.title.PICTURE'|translate")
                                    img.img-rounded(style="width:100%" :src="'/sale/reclamation/proof/' + reclamation.proof_photo|upload")
                        .row(v-show="reclamation.options.length && permission.admin")
                            .col-sm-6.col-sm-offset-6
                                div(v-for="option in reclamation.options")
                                    i.fa.fa-check.mr3
                                    span {{option.name}}
                        .row(v-show="reclamation.status >= 4")
                            .col-sm-6.col-sm-offset-6
                                label
                                    .checkbox.c-checkbox.checkbox-primary.inline
                                        label
                                            input(type="checkbox" name="print_image" v-model="reclamation.print_image"
                                                @change="printImage")
                                            span.fa.fa-check
                                    span {{'default.PRINTIMAGE'|translate}}

                        .panel-footer.clearfix(v-opti-empty)
                            OptiButton(type="danger" :title="'reclamations.action.cancel.BTN'|translate|lower"
                                v-if="reclamation.status === 1 || (reclamation.status !== 0 && reclamation.status !== 8 && permission.admin)"
                                text="sale.reclamation.action.cancel.confirm" @confirmed="goCancel") {{'sale.reclamation.action.cancel.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-remove
                            OptiButton.pull-right(type="success" :title="'sale.reclamation.action.receive.BTN'|translate|lower"
                                v-if="department_id === 49 && reclamation.status === 2 && reclamation.shipmentItems[0] && !reclamation.shipmentItems[0].delivered"
                                text="sale.reclamation.action.receive.confirm" @confirmed="goReceiveWarehouse") {{'sale.reclamation.action.receive.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(style="margin-left:5px" type="success" :title="'sale.reclamation.action.beingrepead.BTN'|translate|lower"
                                v-if="reclamation.status === 4 && reclamation.accepted === 1 && permission.admin"
                                text="sale.reclamation.action.beingrepead.confirm" @confirmed="goBeingRepair") {{'sale.reclamation.action.beingrepead.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'sale.reclamation.action.send.BTN'|translate|lower"
                                v-if="(reclamation.status === 4 || reclamation.status === 5) && permission.admin"
                                text="sale.reclamation.action.send.confirm" @confirmed="goSend") {{'sale.reclamation.action.send.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'sale.reclamation.action.receive.BTN'|translate|lower"
                                v-if="reclamation.status === 6 || (reclamation.status === 0 && reclamation.shipmentItems[0] && !reclamation.shipmentItems[0].delivered)"
                                text="sale.reclamation.action.receive.confirm" @confirmed="goReceive") {{'sale.reclamation.action.receive.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check
                            OptiButton.pull-right(type="success" :title="'sale.reclamation.action.done.BTN'|translate|lower"
                                v-if="reclamation.status === 7" :disabled="(reclamation.accepted === 4 && !model.product.id) || (payment_invalid && reclamation.accepted === 4)"
                                text="reclamations.action.done.confirm" @confirmed="goDone") {{'sale.reclamation.action.done.BTN'|translate}}
                                template(#icon)
                                    i.fa.fa-check

        OptiReclamationServicesView(v-if="!loading && reclamation.services.length"
            :data="reclamation.services" path="sale/reclamation")

        OptiReclamationSaleDetailsDecision(v-if="!loading && reclamation.status === 3 && permission.admin"
            @go-considered="goConsidered")

        OptiReclamationSaleDetailsChangeDecision(v-if="!loading && [4, 5, 6, 7].includes(reclamation.status) && [3, 5].includes(reclamation.accepted) && permission.change_decision"
            :decision-id="reclamation.accepted"

            @go-change-decision="goChangeDecision")

        .row
            .col-md-10.col-md-offset-1(v-if="!loading && permission.notes")
                OptiNotes(table="reklamacje_plus_notes" table-key="reklamacje_plus_id" :id="reclamation.id"
                    :upload="true" :notes="reclamation.notes" @update-notes="n => reclamation.notes = n")
            .col-xs-12
                OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {ReceiptNotice} from '@/js/app/vue/helpers/ReceiptNotice'

    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiButtonPrint from '@/js/app/vue/components/Button/OptiButtonPrint'
    import OptiButtonSms from '@/js/app/vue/components/Button/OptiButtonSms'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiButtonTransfer from '@/js/app/vue/components/Button/OptiButtonTransfer'
    import OptiButtonDepository from '@/js/app/vue/components/Button/OptiButtonDepository'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiSelectProduct from '@/js/app/vue/components/Select/OptiSelectProduct'
    import OptiReceiptNoticeValue from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNoticeValue'
    import OptiReceiptNotice from '@/js/app/vue/components/Receipt/Notice/OptiReceiptNotice'
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiPaymentShow from '@/js/app/vue/components/Payments/OptiPaymentShow'
    import OptiShipmentNotice from '@/js/app/vue/components/Shipping/OptiShipmentNotice'
    import OptiReclamationServicesView from '@/js/app/vue/components/Reclamations/OptiReclamationServicesView'
    import OptiReclamationSaleDetailsDecision
        from '@/js/app/vue/components/Reclamations/Sales/Details/OptiReclamationSaleDetailsDecision'
    import OptiReclamationSaleDetailsChangeDecision
        from '@/js/app/vue/components/Reclamations/Sales/Details/OptiReclamationSaleDetailsChangeDecision'
    import OptiNotes from '@/js/app/vue/components/OptiNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiReclamationSaleDetails',
        components: {
            OptiButtonHistory,
            OptiButtonPrint,
            OptiButtonSms,
            OptiButton,
            OptiButtonDepository,
            OptiButtonTransfer,
            OptiProduct,
            OptiPatient,
            OptiSelectProduct,
            OptiReceiptNoticeValue,
            OptiReceiptNotice,
            OptiPayment,
            OptiPaymentShow,
            OptiShipmentNotice,
            OptiReclamationServicesView,
            OptiReclamationSaleDetailsDecision,
            OptiReclamationSaleDetailsChangeDecision,
            OptiNotes,
            OptiDimmer
        },
        data() {
            return {
                reclamation: {},
                product_price_from_sale: 0,
                sms: {},
                navi: {},
                department_id: this.$uac.user.activeDepartmentId,
                shipment_exists: false,

                accepted: -1,
                model: {
                    product: {},
                    depository: {}
                },

                form_payment: {},
                payment: [],
                change_price_payment: 0,
                noticeReclamation: {},

                permission: {
                    admin: this.$uac.permission('sale.reclamation.admin'),
                    change_decision: this.$uac.permission('sale.reclamation.change_decision'),
                    notes: this.$uac.permission('sale.reclamation.notes')
                },
                payment_invalid: true,
                loading: true
            }
        },
        mounted() {
            API.get('sale/reclamation/' + this.$state.params.id).then(res => {
                this.getData(res.data)
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('sale.reclamation.notify.error.LOAD')
            })

            if(this.permission.admin) {
                API.get('sale/reclamation/navi/' + this.$state.params.id).then(res => {
                    this.navi = res.data
                }).catch(() => {
                    this.$notify.error('sale.reclamation.navi.notify.error.LOAD')
                })
            }
        },
        methods: {
            printImage() {
                API.post('reclamation/print_image', {
                    type: 'sale',
                    id: this.reclamation.id,
                    print_image: this.reclamation.print_image
                }).catch(() => {
                    this.$notify.error('default.notify.error.PRINTIMAGE')
                })
            },

            getProductPriceFromSale() {
                let _return = 0

                if(this.reclamation.productRelation.price === 'undefined') {
                    return _return
                }

                this.reclamation.sale.items.forEach(item => {
                    if(item.productId === this.reclamation.productRelation.id) {
                        _return = item.valueAfterDiscount / item.quantity
                    }
                })

                return _return
            },
            updateProduct(product) {
                this.model.product = product

                API.post('sale/reclamation/depository', {
                    product_id: this.model.product.id,
                    department_id: this.reclamation.department.id,
                }).then(res => {
                    this.model.depository = res.data
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.PRODUCTNOSTOCK')
                })

                if(Object.keys(this.model.product).includes('price')) {
                    this.reclamation.change_price = Math.round((this.product_price_from_sale - this.model.product.price) * 100) / 100

                    this.change_price_payment = this.reclamation.change_price > 0 ?
                        this.reclamation.change_price : this.reclamation.change_price * -1
                }
            },

            getData(data) {
                this.reclamation = data
                this.product_price_from_sale = this.getProductPriceFromSale()

                if(this.reclamation.status === 7 && this.reclamation.accepted === 5 && this.reclamation.product_id) {
                    this.change_price_payment = this.reclamation.change_price = this.getProductPriceFromSale()
                }

                this.sms = {
                    patient: {
                        id: Object.keys(this.reclamation.sale.patient).includes('id') ? this.reclamation.sale.patient.id : 0,
                        firstName: this.reclamation.client,
                        lastName: '',
                        city: this.reclamation.city,
                        cellPhoneNumber: this.reclamation.cellphonenumber,
                        departmentId: this.reclamation.department_id,
                        status: this.reclamation.accepted ? 'pozytywnie' : 'negatywnie'
                    },
                    tags: {
                        type: 11,
                        ref_id: this.reclamation.id,
                        firstName: this.reclamation.client,
                        reclamationId: this.reclamation.id
                    }
                }

                if(this.reclamation.accepted === 4 || this.reclamation.accepted === 5) {
                    ReceiptNotice.get('sale_reclamation', this.reclamation.id).then(res => {
                        this.noticeReclamation = res
                    }).catch(() => {
                        // brak różnic
                    })
                }

                this.$emit('update-breadcrumb', this.reclamation.status_name)
            },
            goCancel() {
                this.loading = true

                API.post('sale/reclamation/cancel', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.CANCELED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.CANCELED')
                })
            },
            goReceiveWarehouse() {
                this.loading = true

                API.post('sale/reclamation/receive-warehouse', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.RECEIVED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.RECEIVED')
                })
            },
            goBeingRepair() {
                this.loading = true

                API.post('sale/reclamation/beingrepead', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.BEINGREPEAD')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.BEINGREPEAD')
                })
            },
            goSend() {
                this.loading = true

                API.post('sale/reclamation/send', {
                    id: this.reclamation.id
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.SEND')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.SEND')
                })
            },
            goReceive() {
                this.loading = true

                API.post('sale/reclamation/receive', {
                    id: this.reclamation.id,
                    payment: this.payment
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.RECEIVED')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('sale.reclamation.notify.error.RECEIVED')
                })
            },
            goDone() {
                this.loading = true

                API.post('sale/reclamation/done', {
                    id: this.reclamation.id,
                    change_product_id: this.reclamation.accepted === 4 ? this.model.product.id : 0,
                    change_price: this.reclamation.accepted === 4 || this.reclamation.accepted === 5 ? this.reclamation.change_price : 0,
                    payment: this.payment
                }).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.DONE')
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.response.status === 412) {
                        this.$notify.error(error.response.data.message, false)
                    }

                    this.$notify.error('sale.reclamation.notify.error.DONE')
                })
            },

            goConsidered(form) {
                this.loading = true

                API.post('sale/reclamation/considered', form).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.notify.CONSIDERED')
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.response.status === 412) {
                        this.$notify.error(error.response.data.message, false)
                    }

                    this.$notify.error('sale.reclamation.notify.error.CONSIDERED')
                })
            },
            goChangeDecision(form) {
                this.loading = true

                API.post('sale/reclamation/change-decision', form).then(res => {
                    this.getData(res.data)

                    this.$notify.success('sale.reclamation.change.notify.CHANGED')
                }).finally(() => {
                    this.loading = false
                }).catch(error => {
                    if(error.response.status === 412) {
                        this.$notify.error(error.response.data.message, false)
                    }

                    this.$notify.error('sale.reclamation.change.notify.error.CHANGED')
                })
            }
        }
    }
</script>
